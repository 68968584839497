<template>
    <div class="row">
        <CSmartTable
          v-if="data && data.length > 0"
          :items="data"
          :columns="columns"
          :tableProps="{
          striped: true,
          hover: true
        }"
          column-filter
          items-per-page-select
          items-per-page-label="Einträge pro Seite"
          :items-per-page="20"
          column-sorter
          :sorter-value="{ column: 'date', state: 'desc' }"
          pagination
        >
          <template #actionbar="{item}">
            <td nowrap="" width="50%">
                <CButton
                  color="success"
                  size="sm"
                  v-c-tooltip="{content: 'Protokoll', placement:'top'}"
                  v-on:click="exportProtItem(item)"
                >
                  <CIcon name="cil-list-numbered"/>
                  Protokoll
                </CButton>
              &nbsp;
                <CButton
                  color="success"
                  size="sm"
                  v-c-tooltip="{content: 'Übersicht', placement:'top'}"
                  v-on:click="exportItem(item)"
                >
                  <CIcon name="cil-chart-line"/>
                  Überischt
                </CButton>
            </td>
          </template>
        </CSmartTable>
        <no-data v-else />
    </div>
</template>

<script>
import NoData from '@/components/NoData'
const moment = require('moment')
require('moment/locale/de')

export default {

  name: 'TemperaturExport',
  components: {
    NoData
  },
  data () {
    return {
      data: [
        {
          date: moment().format('YYYY-MM'),
          month: moment().format('MM'),
          year: moment().format('YYYY')
        },
        {
          date: moment().subtract(1, 'month').format('YYYY-MM'),
          month: moment().subtract(1, 'month').format('MM'),
          year: moment().subtract(1, 'month').format('YYYY')
        }
      ],
      columns: [
        {
          key: 'date',
          label: 'Monate',
          filter: false,
          sorter: false,
          _style: 'width:50%'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:50%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  mounted () {
    // this.loadMitarbeiter()
  },
  methods: {
    momentDatum: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentUhrzeit: function (date) {
      return moment(date).format('HH:mm:ss')
    },
    exportProtItem (item) {
      window.open(process.env.VUE_APP_API_BASEURL + 'daten/export/protokoll/' + item.year + '/' + item.month, '_blank')
    },
    exportItem (item) {
      window.open(process.env.VUE_APP_API_BASEURL + 'daten/export/uebersicht/' + item.year + '/' + item.month, '_blank')
    }
  }
}
</script>

<style>
.temp-form h5{
  text-transform: uppercase;
}
</style>
