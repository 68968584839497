<template>
  <CRow>
    <CCol :sm="12">
        <h5>
          <CIcon size="lg" name="cil-list-numbered"/> DATEN EXPORTIEREN
        </h5>
    </CCol>
  </CRow>
  <CRow>
    <CCol :sm="12">
      <TemperaturExport />
    </CCol>
  </CRow>
</template>

<script>
import TemperaturExport from '@/components/TemperaturExport'
export default {
  name: 'AppDatenExport',
  components: {
    TemperaturExport
  }
}
</script>
